/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ol: "ol",
    li: "li",
    a: "a",
    h2: "h2",
    div: "div"
  }, _provideComponents(), props.components), {TOC} = _components;
  if (!TOC) _missingMdxReference("TOC", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Fowl and poultry are two names that are often thrown around when talking about birds."), "\n", React.createElement(_components.p, null, "There is a common misconception that they are the same thing, due to the two terms often being used interchangeably. However, they definitely do not mean the same thing."), "\n", React.createElement(_components.p, null, "Maybe this is something that you've been wondering about recently and you're looking for some clarification. Maybe you own some chickens for your backyard and you've wondered what they were - fowl or poultry."), "\n", React.createElement(_components.p, null, "Whatever your reason for coming to this article, we've got your back. This article will tell you exactly what the difference between fowl and poultry is, as well as everything in between."), "\n", React.createElement(TOC, null, React.createElement(_components.p, null, "Table Of Contents"), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#t-1602517808639"
  }, "What do we mean by 'fowl'?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#t-1602517808640"
  }, "What do we mean by 'poultry'?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#t-1602517808641"
  }, "So, what's the difference?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#t-1602517808642"
  }, "Final Word")), "\n")), "\n", React.createElement(_components.p, null, "Let us begin by saying that there is a lot of overlap between poultry and fowl, with some animals technically belonging to both categories."), "\n", React.createElement(_components.p, null, "That sounds really confusing, we know, but don't you worry because we will explain it all in more detail as the article proceeds."), "\n", React.createElement(_components.p, null, "It would be helpful to first gain an understanding of what is meant by fowl and poultry with some informative definitions before we go ahead and discuss the differences between them."), "\n", React.createElement("a", {
    name: "t-1602517808639"
  }), "\n", React.createElement(_components.h2, null, "What do we mean by 'fowl'?"), "\n", React.createElement(_components.p, null, "Although the word 'fowl' is used interchangeably with poultry, it does have its own meaning."), "\n", React.createElement(_components.p, null, "In its most basic state, fowl is the name given to a specific group of animals. In fact, many dictionaries define 'fowl' as being all birds. That is overwhelming, isn't it? With such a broad definition given by many leading dictionaries, it is easy to see why things can get so confusing."), "\n", React.createElement(_components.p, null, "All birds should not be classified as fowl, and here's why...."), "\n", React.createElement(_components.p, null, "Fowl are divided into two categories - landfowl and waterfowl. Landfowl is also known as gamefowl. With this definition in mind, we can far more easily understand what is meant by fowl when we explore what animals are included in each of the categories."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 600px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 66.26506024096386%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGazWuWeVBP/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxIQEf/aAAgBAQABBQJBqAcWgRq8hak8/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABkQAAMAAwAAAAAAAAAAAAAAAAAQMTJxof/aAAgBAQAGPwLRkqp0/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBYXH/2gAIAQEAAT8hO2hS97g6owa+xfJTsRAQuxnNtj//2gAMAwEAAgADAAAAEAsv/8QAFxEBAAMAAAAAAAAAAAAAAAAAARARIf/aAAgBAwEBPxAoNI//xAAXEQADAQAAAAAAAAAAAAAAAAAAASER/9oACAECAQE/EKcMR//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExQVGBkf/aAAgBAQABPxDaKiFOmjD66yHkG/V1lXa+Y2gS5XFAJxceVp8Rn//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"fowl\"\n        title=\"fowl\"\n        src=\"/static/c808083176b3729cf2ebcb3e55114025/3ad8d/fowl.jpg\"\n        srcset=\"/static/c808083176b3729cf2ebcb3e55114025/4d5fb/fowl.jpg 166w,\n/static/c808083176b3729cf2ebcb3e55114025/558f0/fowl.jpg 333w,\n/static/c808083176b3729cf2ebcb3e55114025/3ad8d/fowl.jpg 600w\"\n        sizes=\"(max-width: 600px) 100vw, 600px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Examples of landfowl (gamefowl) include: - turkeys, pheasants, grouse, chickens (roosters and hens), and quail amongst several lesser-known species."), "\n", React.createElement(_components.p, null, "Examples of waterfowl include:- ducks, geese, swans, and the mallard."), "\n", React.createElement(_components.p, null, "Being able to picture the specific animals when trying to fully understand the meaning of the term fowl is particularly useful."), "\n", React.createElement(_components.p, null, "Now that you have a mental image of these animals, it is likely that you better understand the differences between the two categories of fowl, and have a wider understanding of the term fowl in general."), "\n", React.createElement(_components.p, null, "It is now that we can define the term 'poultry'."), "\n", React.createElement("a", {
    name: "t-1602517808640"
  }), "\n", React.createElement(_components.h2, null, "What do we mean by 'poultry'?"), "\n", React.createElement(_components.p, null, "Poultry is, as we have already said countless times, often used synonymously with the word 'fowl'. However, the proper definition of this word is different from that of fowl."), "\n", React.createElement(_components.p, null, "Poultry is a more specific term for any bird that is domesticated or kept by humans for their eggs or flesh and even their feathers. Already we expect that your brain is conjuring up images of chickens, as they tend to be the most common poultry out there. You are not wrong about this."), "\n", React.createElement(_components.p, null, "Chickens are indeed an example of poultry. Other examples of poultry are turkeys and quails. Oddly enough, even the ostrich is classed as poultry (more on this later!)."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 600px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 66.86746987951807%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAcdxc6Zgx//EABcQAAMBAAAAAAAAAAAAAAAAAAABAhH/2gAIAQEAAQUCmdHCGiNGM//EABYRAAMAAAAAAAAAAAAAAAAAABARIf/aAAgBAwEBPwGIf//EABYRAAMAAAAAAAAAAAAAAAAAABARIf/aAAgBAgEBPwGsf//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABkQAQEBAQEBAAAAAAAAAAAAAAEAIRExUf/aAAgBAQABPyEx1YudYBkDG87r9vV//9oADAMBAAIAAwAAABB7z//EABYRAQEBAAAAAAAAAAAAAAAAAAABUf/aAAgBAwEBPxCYK//EABYRAAMAAAAAAAAAAAAAAAAAAAEQUf/aAAgBAgEBPxA0X//EABoQAQEBAQADAAAAAAAAAAAAAAERADFBUXH/2gAIAQEAAT8QuAFmVRYK5CDpoSvks1Up4fHrdd//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"poultry\"\n        title=\"poultry\"\n        src=\"/static/cda54a72e7136d7a94d1959bed8a4660/3ad8d/poultry.jpg\"\n        srcset=\"/static/cda54a72e7136d7a94d1959bed8a4660/4d5fb/poultry.jpg 166w,\n/static/cda54a72e7136d7a94d1959bed8a4660/558f0/poultry.jpg 333w,\n/static/cda54a72e7136d7a94d1959bed8a4660/3ad8d/poultry.jpg 600w\"\n        sizes=\"(max-width: 600px) 100vw, 600px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "However, a distinction must be made between poultry and game. Game birds are any bird that is hunting for sport. These include pheasants and wild turkeys, ducks, and geese."), "\n", React.createElement(_components.p, null, "Note the specific reference to the wild. Anything that lives in the wild and is not domesticated, but is still hunted for sport or food will fall under the category of game."), "\n", React.createElement(_components.p, null, "Of course, some animals overlap, presenting some gray areas. For example, even though we have stated that wild ducks, turkeys and geese are game, you can also have domesticated ducks, turkeys, and geese. These would then be classed as poultry rather than game."), "\n", React.createElement(_components.p, null, "Now we have the definitions of both 'poultry' and 'fowl', we can finally start distinguishing between the two."), "\n", React.createElement("a", {
    name: "t-1602517808641"
  }), "\n", React.createElement(_components.h2, null, "So, what's the difference?"), "\n", React.createElement(_components.p, null, "As you can probably see already, there is definitely lots of overlap between the two terms."), "\n", React.createElement(_components.p, null, "You may even have begun to identify that there are some animals that seem to fall into both of the categories. The chicken, for example, is both an example of landfowl and also belongs to the category of poultry. We can see how this may be confusing!"), "\n", React.createElement(_components.p, null, "To find one specific difference between poultry and fowl is difficult because it is not as black and white as that. As we have said, animals can seemingly belong to both categories and even belong in subcategories within these larger groups."), "\n", React.createElement(_components.p, null, "A great example to use when trying to decipher the difference between both of these words is that of the ostrich. We know that the ostrich is a bird. We know that ostriches are often kept for their meat, eggs, and feathers. This would insinuate that the ostrich is poultry."), "\n", React.createElement(_components.p, null, "We also know that ostriches live in the wild as well as being domesticated. This may cause someone to think that an ostrich is a fowl, more specifically, landfowl."), "\n", React.createElement(_components.p, null, "Only one of these is true."), "\n", React.createElement(_components.p, null, "Whilst the ostrich is most certainly poultry, it does not fall into either subcategory of fowl. Thus proving that 'poultry' and 'fowl' cannot be properly used interchangeably."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 600px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 66.86746987951807%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgMA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAZCgiq4Yf//EABsQAAMAAgMAAAAAAAAAAAAAAAABAhESEyEi/9oACAEBAAEFAk+lbNMmhXmuakf/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BbX//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFH/8QAGxAAAQQDAAAAAAAAAAAAAAAAAAECETEQIUH/2gAIAQEABj8CoTRTiCe4/8QAGxAAAwEAAwEAAAAAAAAAAAAAAAERIUFRYYH/2gAIAQEAAT8hRHDwYfYRpFCemRB9Cwn/2gAMAwEAAgADAAAAEBfP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAEFh/9oACAEDAQE/EI0f/8QAFxEAAwEAAAAAAAAAAAAAAAAAABEhUf/aAAgBAgEBPxB0TD//xAAcEAEBAQACAwEAAAAAAAAAAAABEQAhMUFhwdH/2gAIAQEAAT8QWDSE5RuJabir80YwPkLfeBEh3wzrANFA8j81QA1ve//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"fowl vs poultry 1\"\n        title=\"fowl-vs-poultry\"\n        src=\"/static/970ec72b4341e10838aac40c3fed2a01/3ad8d/fowl-vs-poultry-1.jpg\"\n        srcset=\"/static/970ec72b4341e10838aac40c3fed2a01/4d5fb/fowl-vs-poultry-1.jpg 166w,\n/static/970ec72b4341e10838aac40c3fed2a01/558f0/fowl-vs-poultry-1.jpg 333w,\n/static/970ec72b4341e10838aac40c3fed2a01/3ad8d/fowl-vs-poultry-1.jpg 600w\"\n        sizes=\"(max-width: 600px) 100vw, 600px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Likewise, an animal can belong to either subcategory of fowl - land or water - but not be poultry."), "\n", React.createElement(_components.p, null, "One such animal is the racer pigeon. This animal is not bred or domesticated for its meat, eggs, or feathers, and although it is kept for racing it does not meet the criteria for poultry. It is, however, an example of fowl."), "\n", React.createElement(_components.p, null, "If a specific aspect of fowl and poultry had to be pinpointed to provide a solid difference between, the most obvious would be to say that generally, poultry is any bird that is kept or domesticated by humans to use them for their eggs, feathers, or flesh (for meat)."), "\n", React.createElement(_components.p, null, "These birds can also technically belong to the category of fowl. However, just because an animal is classed as a fowl, does not mean they are also poultry and vice versa."), "\n", React.createElement(_components.p, null, "Many people will stand by the definition that fowl is a subcategory of poultry. Others will flip this on its head and say that poultry is a subcategory of fowl."), "\n", React.createElement(_components.p, null, "Hopefully, after reading this article you can see that it is not so simple as this and there is a lot of overlap between the two terms."), "\n", React.createElement("a", {
    name: "t-1602517808642"
  }), "\n", React.createElement(_components.h2, null, "Final Word"), "\n", React.createElement(_components.p, null, "We are sure that you can see that trying to define the difference between fowl and poultry is not as clear cut as you may have hoped."), "\n", React.createElement(_components.p, null, "That being said, we do hope this article has illuminated the subtle ways that we can tell each of the categories apart from each other."), "\n", React.createElement(_components.p, null, "The most important step to take when trying to identify whether a particular animal belongs to 'fowl' or 'poultry' is to think first about whether it meets the requirements to be classed as a waterfowl or a landfowl."), "\n", React.createElement(_components.p, null, "To do this, a small amount of research may be helpful to determine the different animal classifications of birds and to see where yours fits in. a chicken, for example, is an example of landfowl, whereas a duck is a waterfowl."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 600px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 66.86746987951807%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAV6rSW4qOf/EABgQAQEBAQEAAAAAAAAAAAAAAAEAAhET/9oACAEBAAEFAsbchehw0yxf/8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQMBAT8Bh//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAIDAQAAAAAAAAAAAAAAAAABAhExIP/aAAgBAQAGPwLS24mcf//EABgQAQADAQAAAAAAAAAAAAAAAAEAESFB/9oACAEBAAE/IWmKZWzAEw1gYGKVDUQceRZP/9oADAMBAAIAAwAAABBHL//EABYRAQEBAAAAAAAAAAAAAAAAABEBEP/aAAgBAwEBPxCUhn//xAAWEQEBAQAAAAAAAAAAAAAAAAARAAH/2gAIAQIBAT8QNxJv/8QAHhABAQACAAcAAAAAAAAAAAAAAREAITFRYYGRsdH/2gAIAQEAAT8QoQLGcpr05qoABrPbNwMJwPmRoA0mJlBhXq4vln//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"fowlpoultry\"\n        title=\"fowlpoultry\"\n        src=\"/static/d7e0952430df088f420cda59cb92a96e/3ad8d/fowlpoultry.jpg\"\n        srcset=\"/static/d7e0952430df088f420cda59cb92a96e/4d5fb/fowlpoultry.jpg 166w,\n/static/d7e0952430df088f420cda59cb92a96e/558f0/fowlpoultry.jpg 333w,\n/static/d7e0952430df088f420cda59cb92a96e/3ad8d/fowlpoultry.jpg 600w\"\n        sizes=\"(max-width: 600px) 100vw, 600px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Then to determine whether it is poultry, you should assess whether the bird in question is kept or domesticated to get eggs, feathers, or meat from it. This will tell you that it is poultry."), "\n", React.createElement(_components.p, null, "Again, the humble chicken is kept in domestication for exactly this reason, telling us it is both fowl and poultry."), "\n", React.createElement(_components.p, null, "Again, the ostrich is a great example here. Its classification is that of a flightless bird, and so it does not fall into either category of fowl."), "\n", React.createElement(_components.p, null, "That being said, we know that it is bred and kept by humans for its eggs, meat, and feathers, telling us that it is poultry."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-resp-iframe-wrapper\" style=\"padding-bottom: 56.42857142857143%; position: relative; height: 0; overflow: hidden; \" > <div class=\"embedVideo-container\"> <iframe title=\"\" src=\"https://www.youtube.com/embed/vtAJZyXSLoA?rel=0\" class=\"embedVideo-iframe\" style=\"border:0; position: absolute; top: 0; left: 0; width: 100%; height: 100%; \" loading=\"eager\" allowfullscreen=\"\" sandbox=\"allow-same-origin allow-scripts allow-popups\"></iframe> </div> </div>"
    }
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
